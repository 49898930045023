import React from "react"
import RichContentColumns from "./RichContentColumns/RichContentColumns.js"

const CategoryFooterLayouts = ({ layoutData }) => {
  const layoutType = layoutData && layoutData.fieldGroupName || "No field group name found";
  // Default component
  const Default = (props) => (
    <div>
      In CategoryFooterLayouts the mapping of this component is missing: {layoutType}
      <br />
      <pre>{ JSON.stringify(props.layouts) }</pre>
    </div>
  );

  // Mapping the fieldGroupName(s) to our components
  const layouts = {
    category_Categorycomponents_Footer_RichContentColumns: RichContentColumns,
    default: Default,
  }
console.log( layouts );

  // If layout type is not existing in our mapping, it shows our Default instead.
  const ComponentTag = layouts[layoutType]
    ? layouts[layoutType]
    : layouts["default"]

  return (
    <ComponentTag {...layoutData} layouts={layouts} type={"category"} />
  )
}

export default CategoryFooterLayouts
