import React from "react"
import './RichContentColumns.scss';

export default function RichContentColumns({ header, columns, theme }) {

  return (
    <section className={`rich-content-columns-section ${theme}`}>
      { header && <h3>{ header }</h3> }

      <div className={`rich-content-columns rich-content-columns-${columns ? columns.length : 0}`}>
        {columns && columns.map((col, i) => (
          <div
            key={i}
            className="rich-content-column"
          >
            <div
              dangerouslySetInnerHTML={{ __html: col.content }} 
            />
          </div>
        ))}
      </div>
    </section>
  );
}
