import React, { useEffect } from "react";
import { useLocation } from '@gatsbyjs/reach-router';
import { useTranslation } from 'react-i18next';
import { ParallaxProvider } from 'react-scroll-parallax';

import PostWrapper from './PostWrapper';
import useComponentWillMount from 'src/hooks/useComponentWillMount';
import setLanguage from 'src/utils/setLanguage';
// import replaceAmpersand from 'src/utils/replaceAmpersand';
import DefaultHead from 'src/components/head-defaults';
// import Seo from 'src/components/seo';

import Layout from "src/components/Layout";

const Post = ( props ) => {
  const location = useLocation();
  const {i18n} = useTranslation();
  useComponentWillMount(() => {setLanguage(location.pathname, i18n)});
  useEffect(() => {
    // localStorage.setItem('articleFallbackUrl', '');
  }, []);

  console.log( props.pageContext )

  return (
    <Layout theme="light">
      <div className="post-page-wrapper">
        <ParallaxProvider>
          <PostWrapper {...props} />
        </ParallaxProvider>
      </div>
    </Layout>
  );
};

export default Post;


export const Head = ({ pageContext }) => {
  return <DefaultHead />;
  /*
  const { title } = pageContext;
  const postHeroObj = pageContext.components.contents.find(c => c.fieldGroupName === "post_Components_Contents_ArticleHero");
  return (
    <Seo
      title={replaceAmpersand(title) || "Untitled"}
      description={postHeroObj && postHeroObj.byline}
      imageUrl={postHeroObj && ( ( postHeroObj.socialHeroImage && postHeroObj.socialHeroImage.sourceUrl ) || ( postHeroObj.heroImage && postHeroObj.heroImage.sourceUrl ) )}
      articleUrl={pageContext.url}
      articleLang={pageContext.language.slug}
      pageContext={pageContext}
      type="article"
    />
  )
  */  
}
