import React from "react";
import { useParallaxController } from 'react-scroll-parallax';

import Layout from "components/Layout";
import CategoryLayouts from "components/CategoryLayouts";
import CategoryFooterLayouts from "components/CategoryFooterLayouts";
import { TopArticlesCategory } from "components"

const Category = (props) => {
  const parallaxController = useParallaxController();
  const updateParallaxState = () => parallaxController.update();

  const { pageContext } = props;
  const { categoryComponents } = pageContext;
  const layouts = categoryComponents.components || [];
  const footerLayouts = categoryComponents.footer || [];

  return (
    <div className="category-page-wrapper">
      <Layout>
        {layouts.map((layout, index) => (
          <CategoryLayouts key={index} layoutData={layout} />
        ))}

        <TopArticlesCategory category={pageContext} updateParallaxState={updateParallaxState} />

        <div className="category-footer container">
          {footerLayouts.map((layout, index) => (
            <CategoryFooterLayouts key={index} layoutData={layout} />
          ))}        
        </div>
      </Layout>
    </div>
  ) 
}

export default Category;
