import React from 'react';
import { Link } from 'gatsby';

import "./Pagination.scss"

export default function Pagination(props){

  const { path, pageContext: { page, pages } } = props
  const cleanPath = path.replace( /\d+\/$/, '' );

  let prevIndex = page - 1,
      nextIndex = page + 1;

  if( nextIndex > pages ) {
    nextIndex = 0;
  }

  const links = [];
  let idx = 0;
  for( let i = page - 3; i <= page + 3; i++ ) {
    if( i < 1 ) {
      continue;
    }

    if( i == page ) {
      links.push( <span key={idx++} className="pagination-link pagination-link-current">{ page }</span> );
    }
    else if( i === 1 ){
      links.push( <Link key={idx++} to={`${cleanPath}`} className="pagination-link">{i}</Link>  );
    }
    else if( i < pages ) {
      links.push( <Link key={idx++} to={`${cleanPath + i}/`} className="pagination-link">{i}</Link>  );
    }
  }

  if( pages > page + 3 ) {
    if( pages == page + 4 ) {
      // just add one more
      links.push( <Link key={idx++} to={`${cleanPath + pages}/`} className="pagination-link">{pages}</Link>  );
    }
    else {
      links.push( <span key={idx++} className="pagination-spacer">...</span> );
      links.push( <Link key={idx++} to={`${cleanPath + pages}/`} className="pagination-link">{pages}</Link>  );
    }
  }

  if( page - 3 > 1 ) {
    if( page - 3 == 2 ) {
      // just add one more
      links.unshift( <Link key={idx++} to={`${cleanPath}`} className="pagination-link">1</Link>  );
    }
    else {
      links.unshift( <span key={idx++}  className="pagination-spacer">...</span> );
      links.unshift( <Link key={idx++}  to={`${cleanPath}`} className="pagination-link">1</Link>  );
    }
  }


  return (
    <div className="pagination-container">
      <div className="pagination-inner">
        { prevIndex > 0 && <Link to={prevIndex > 1 ? `${cleanPath + prevIndex}/` : cleanPath } className="pagination-link pagination-link-prev">Prev</Link> }

        { pages > 1 && links }

        { nextIndex > 0 && <Link to={`${cleanPath + nextIndex}/`} className="pagination-link pagination-link-next">Next</Link> }
      </div>
    </div>
  )
}